import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { LocalStorageServiceService } from './local-storage-service.service';
import { USER_ROLE_ID } from '@constants/meta-data';

@Injectable()
export class NavigateIfPrivilegeExist {
  constructor(
    private localStorageService: LocalStorageServiceService,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot) {
    const data = get(route.data, 'privilege', '');
    const roleId = localStorage.getItem('userRoleId');
    if (
      [
        'line-management',
        'user-management',
        'ng-code',
        'alarm-code',
        'alerts-management',
        'safety-security',
        'configuration',
      ].includes(data)
    ) {
      if (roleId === USER_ROLE_ID.ADMIN) {
        return true;
      }
      this.navigateToHome();
      return false;
    }
    return true;
  }

  navigateToHome() {
    if (this.localStorageService.getAccessToken()) {
      this.router.navigate(['dashboard']);
    } else this.router.navigate(['login']);
  }
}
