import { Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { NavigateIfPrivilegeExist } from '@services/route-guard.service';

export const routes: Routes = [
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./features/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
    data: { showSidebar: false, showHeader: false },
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./features/auth/login/login.component').then(
        (m) => m.LoginComponent
      ),
    data: { showSidebar: false, showHeader: false },
  },
  {
    path: 'chart',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'daily-production',
        pathMatch: 'full',
      },
      {
        path: 'monthly-production',
        loadComponent: () =>
          import(
            './features/chart-views/monthly-production/monthly-production.component'
          ).then((m) => m.MonthlyProductionComponent),
      },
      {
        path: 'daily-production',
        loadComponent: () =>
          import(
            './features/chart-views/daily-production/daily-production.component'
          ).then((m) => m.DailyProductionComponent),
      },
      {
        path: 'module-equipment',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './features/chart-views/module-equipment/module-equipment.component'
              ).then((m) => m.ModuleEquipmentComponent),
          },
          {
            path: 'quality-analysis',
            loadComponent: () =>
              import(
                './features/chart-views/quality-analysis/quality-analysis.component'
              ).then((m) => m.QualityAnalysisComponent),
          },
        ],
      },
      {
        path: 'eq-failure-downtime',
        loadComponent: () =>
          import(
            './features/chart-views/eq-failure-downtime/eq-failure-downtime.component'
          ).then((m) => m.EqFailureDowntimeComponent),
      },
      {
        path: 'line-management',
        loadComponent: () =>
          import(
            './features/chart-views/line-management/line-management.component'
          ).then((m) => m.LineManagementComponent),
        canActivate: [NavigateIfPrivilegeExist],
        data: { privilege: 'line-management' },
      },
      {
        path: 'line-status',
        loadComponent: () =>
          import(
            './features/chart-views/line-status/line-status.component'
          ).then((m) => m.LineStatusComponent),
      },
      {
        path: 'alarm-code',
        loadComponent: () =>
          import('./features/chart-views/alarm-code/alarm-code.component').then(
            (m) => m.AlarmCodeComponent
          ),
        canActivate: [NavigateIfPrivilegeExist],
        data: { privilege: 'alarm-code' },
      },
      {
        path: 'ng-code',
        loadComponent: () =>
          import('./features/chart-views/ng-code/ng-code.component').then(
            (m) => m.NgCodeComponent
          ),
        canActivate: [NavigateIfPrivilegeExist],
        data: { privilege: 'ng-code' },
      },
      {
        path: 'ms-progress',
        loadComponent: () =>
          import(
            './features/chart-views/progress-ms/progress-ms.component'
          ).then((m) => m.ProgressMsComponent),
      },
      {
        path: 'alerts-management',
        loadComponent: () =>
          import(
            './features/chart-views/alerts-management/alerts-management.component'
          ).then((m) => m.AlertsManagementComponent),
        canActivate: [NavigateIfPrivilegeExist],
        data: { privilege: 'alerts-management' },
      },
      {
        path: 'hourly-production',
        loadComponent: () =>
          import(
            './features/chart-views/hourly-production/hourly-production.component'
          ).then((m) => m.HourlyProductionComponent),
      },
      {
        path: 'productivity-status',
        children: [
          {
            path: '',
            loadComponent: () =>
              import(
                './features/chart-views/productivity-status/productivity-status.component'
              ).then((m) => m.ProductivityStatusComponent),
          },
          {
            path: 'daily-bekido',
            loadComponent: () =>
              import(
                './features/chart-views/bekido-daily-status/bekido-daily-status.component'
              ).then((m) => m.BekidoDailyStatusComponent),
          },
          {
            path: 'daily-productivity',
            loadComponent: () =>
              import(
                './features/chart-views/productivity-daily-status/productivity-daily-status.component'
              ).then((m) => m.ProductivityDailyStatusComponent),
          },
        ],
      },
    ],
  },
  {
    path: 'safety-security',
    loadComponent: () =>
      import(
        './features/chart-views/safety-security-details/safety-security-details.component'
      ).then((m) => m.SafetySecurityDetailsComponent),
    canActivate: [AuthGuardService, NavigateIfPrivilegeExist],
    data: { privilege: 'safety-security' },
  },
  {
    path: 'user-management',
    loadComponent: () =>
      import('./features/user-management/user-management.component').then(
        (m) => m.UserManagementComponent
      ),
    canActivate: [AuthGuardService, NavigateIfPrivilegeExist],
    data: { privilege: 'user-management' },
  },
  {
    path: 'configuration',
    canActivate: [AuthGuardService, NavigateIfPrivilegeExist],
    data: { privilege: 'configuration' },
    children: [
      {
        path: '',
        redirectTo: 'manage-plants',
        pathMatch: 'full',
      },
      {
        path: 'manage-plants',
        loadComponent: () =>
          import(
            './features/configuration-management/manage-plants/manage-plants.component'
          ).then((m) => m.ManagePlantsComponent),
      },
      {
        path: 'loss-reason',
        loadComponent: () =>
          import(
            './features/configuration-management/production-loss-reason/production-loss-reason.component'
          ).then((m) => m.ProductionLossReasonComponent),
      },
      {
        path: 'line-info-input',
        loadComponent: () =>
          import(
            './features/configuration-management/line-information-input/line-information-input.component'
          ).then((m) => m.LineInformationInputComponent),
      },
    ],
  },
];
