import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, Subscription } from 'rxjs';
import { LoginComponent } from './features/auth/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { HeaderComponent } from './shared/header/header.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { SidebarService } from './shared/sidebar/sidebar.service';
import { ToasterComponent } from './shared/toaster/toaster.component';
import { CommonService } from '@services/common.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    LoginComponent,
    DashboardComponent,
    LoaderComponent,
    TranslateModule,
    SidebarComponent,
    HeaderComponent,
    CommonModule,
    ToasterComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  showSidebar: boolean = false;
  showHeader: boolean = false;
  private routerSubscription?: Subscription;
  isMobileView: boolean = false;

  constructor(
    private translateService: TranslateService,
    public sidebarService: SidebarService,
    private router: Router,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.commonService.isMobileSubject.subscribe((res: any) => {
      this.isMobileView = res;
    });
    this.translateService.setDefaultLang('en');
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let route = this.router.routerState.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
        const currentRouteData = route.snapshot.data;
        this.showSidebar =
          currentRouteData['showSidebar'] !== undefined
            ? currentRouteData['showSidebar']
            : true;
        this.showHeader =
          currentRouteData['showHeader'] !== undefined
            ? currentRouteData['showHeader']
            : true;
      });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription?.unsubscribe();
    }
  }
}
