import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ToastService, ToastMessage } from './toaster.service';

@Component({
  selector: 'app-toaster',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss',
})
export class ToasterComponent {
  toastMessage: ToastMessage | null = null;
  private toastTimeout: any;

  constructor(private toastService: ToastService) {
    this.toastService.toastMessage$.subscribe((message) => {
      this.toastMessage = message;
      clearTimeout(this.toastTimeout);
      if (message) {
        this.toastTimeout = setTimeout(
          () => this.toastService.clearToast(),
          3000
        );
      }
    });
  }

  ngOnDestroy() {
    clearTimeout(this.toastTimeout);
  }
}
