<app-toaster></app-toaster>
<app-loader></app-loader>
@if(showSidebar){
<app-sidebar></app-sidebar>
} @if(showHeader){
<app-header *ngIf="showHeader"></app-header>
}
<div
  class="main-container"
  [ngClass]="{
    'main-container-padding': (sidebarService.isVisible | async) && showSidebar,
    'custom-padding': !(sidebarService.isVisible | async),
    'hide-side-content':
      (sidebarService.isVisible | async) && showSidebar && isMobileView
  }"
>
  <router-outlet></router-outlet>
</div>
